import React, { useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import "./recharge.css";

function Recharge({ profile, getProfile }) {
    const [bank, setBank] = useState(null);
    const [setting, setSetting] = useState(JSON.parse(localStorage.getItem("setting")));
    const [payment, setPayment] = useState([]);
    const [newMoney, setNewMoney] = useState(null);
    const [loading, setLoading] = useState(false);
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors }
    } = useForm();
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/auth/getbank`, {})
            .then((res) => {
                setBank(res.data.data);
            })
            .catch((err) => setBank(null));
        axios
            .get(`${process.env.REACT_APP_API_URL}/setting/get`, {})
            .then((res) => {
                if (setting != res.data.data[0]) {
                    setSetting(res.data.data[0]);
                    localStorage.setItem("setting", JSON.stringify(res.data.data[0]));
                }
            })
            .catch((err) => setSetting(null));
        axios
            .get(`${process.env.REACT_APP_API_URL}/payment/paymentus`, {})
            .then((res) => {
                if (res.data.data) setPayment(res.data.data);
            })
            .catch((err) => setPayment(null));
    }, []);
    const [activeOption, setActiveOption] = useState(1);
    const handleOptionClick = (option) => {
        setActiveOption(option);
    };
    const [activeOption2, setActiveOption2] = useState("Techcombank");
    const handleOptionClick2 = (option) => {
        setActiveOption2(option);
    };
    const onSubmit = (data) => {
        const formData = {
            money: Number(data.money.replaceAll(".", "").replaceAll(",", "")),
            type_payment: "NẠP",
            detail: data.detail,
            status_payment: "Pending",
            user: profile._id
        };
        if (Number(data.money.replaceAll(".", "").replaceAll(",", "")) <= 0 || typeof Number(data.money.replaceAll(".", "").replaceAll(",", "")) !== "number") {
            swal("Thông báo", "Vui lòng nhập số tiền hợp lệ", "error");
            return false;
        }
        if (Number(data.money.replaceAll(".", "").replaceAll(",", "")) < setting?.minnap) {
            swal("Chú ý", `Số tiền nạp tối thiểu là ${(setting?.minnap).toLocaleString('vi-VN')}`, "warning");
            return false;
        }
        if (Number(data.money.replaceAll(".", "").replaceAll(",", "")) > setting?.maxnap) {
            swal("Chú ý", `Số tiền nạp tối đa là ${(setting?.maxnap).toLocaleString('vi-VN')}`, "warning");
            return false;
        }
        if (activeOption != 0) {
            if (payment) {
                const count = payment.filter((obj) => obj.status_payment === "Pending" && obj.type_payment === "NẠP");
                if (count.length > 1) {
                    swal("Thông báo", `Bạn đang có ${count.length} lệnh nạp tiền chưa xử lý, không thể tạo thêm lệnh nạp tiền.`, "error");
                    return false;
                }
            }
            setLoading(true);
            axios
                .post(`${process.env.REACT_APP_API_URL}/payment/withDraw`, formData)
                .then((res) => {
                    swal("Đang chờ xử lý", "Quý khách đã tạo lệnh thành công, vui lòng chuyển khoản đúng nội dung để được cộng điểm", "success");
                    getProfile();
                    setLoading(false);
                })
                .catch((err) => {
                    swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã xảy ra lỗi", "error");
                    setLoading(false);
                });
        } else {
            setLoading(true);
            axios
                .post(`${process.env.REACT_APP_API_URL}/bank/createTrans`, {
                    amount: Number(data.money.replaceAll(".", "").replaceAll(",", "")),
                    type: activeOption2
                })
                .then((res) => {
                    window.location.href = res.data.data.url;
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                    swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Ngân hàng bảo trì, vui lòng chọn ngân hàng khác", "error");
                });
        }
    };
    return (
        <>
            {loading === true ? (
                <div className="loading">
                    <div className="loader"></div>
                </div>
            ) : null}
            <div className="recharge">
                {profile && (
                    <>
                        <div className="menu-recharge">
                            {/*<div className={`menu-item-recharge ${activeOption == 0 ? "active" : ""}`} onClick={() => handleOptionClick(0)}>
                                <img src={require("../../../image/onlinebanking.png")} />
                                <span>Nạp tiền siêu tốc</span>
                            </div>*/}
                            <div className={`menu-item-recharge ${activeOption == 1 ? "active" : ""}`} onClick={() => handleOptionClick(1)}>
                                <img src={require("../../../image/banktransfer.png")} />
                                <span>Ngân hàng nội địa</span>
                            </div>
                            <div className={`menu-item-recharge ${activeOption == 2 ? "active" : ""}`} onClick={() => handleOptionClick(2)}>
                                <img src={require("../../../image/momo.jpeg")} />
                                <span>Ví Momo</span>
                            </div>
                        </div>
                        <div className="content-recharge">
                            <div className="top_title">
                                <div className="txt">Thông tin nạp tiền</div>
                            </div>
                            {activeOption == 0 && (
                                <div className="content_bank">
                                    <h2>Chọn ngân hàng</h2>
                                    <ul className="tab-navigation tab-game tab-bank">
                                        <li className={activeOption2 === "Techcombank" ? "active" : ""} onClick={() => handleOptionClick2("Techcombank")}>
                                            <img src="https://api.vietqr.io/img/TCB.png" />
                                        </li>
                                        <li className={activeOption2 === "Vietcombank" ? "active" : ""} onClick={() => handleOptionClick2("Vietcombank")}>
                                            <img src="https://api.vietqr.io/img/VCB.png" />
                                        </li>
                                        <li className={activeOption2 === "BIDV" ? "active" : ""} onClick={() => handleOptionClick2("BIDV")}>
                                            <img src="https://api.vietqr.io/img/BIDV.png" />
                                        </li>
                                    </ul>
                                    <ul className="tab-navigation tab-game tab-bank">
                                        <li className={activeOption2 === "MBBANK" ? "active" : ""} onClick={() => handleOptionClick2("MBBANK")}>
                                            <img src="https://api.vietqr.io/img/MB.png" />
                                        </li>
                                        <li className={activeOption2 === "TPBANK" ? "active" : ""} onClick={() => handleOptionClick2("TPBANK")}>
                                            <img src="https://api.vietqr.io/img/TPB.png" />
                                        </li>
                                        <li className={activeOption2 === "MOMO" ? "active" : ""} onClick={() => handleOptionClick2("MOMO")}>
                                            <img src="/images/logo_momo.png" />
                                        </li>
                                    </ul>
                                </div>
                            )}
                            {activeOption == 1 && (
                                <div className="content_bank">
                                    {bank &&
                                        bank?.map((item) =>
                                            item.isShow && item.name_bank.toLowerCase() != "momo" ? (
                                                <div className="item-bank" style={{ width: `${item?.qr ? "90%" : null}` }}>
                                                    {item.qr && (
                                                        <div>
                                                            <img src={item.qr} style={{ marginRight: "15px", width: "150px", float: "left" }} />
                                                        </div>
                                                    )}
                                                    <div>
                                                        Tên ngân hàng: <b style={{ color: "red" }}>{item.name_bank}</b>
                                                    </div>
                                                    <div>
                                                        Số tài khoản: <b>{item.stk}</b>
                                                    </div>
                                                    <div>
                                                        Tên người nhận: <b>{item.fullname}</b>
                                                    </div>
                                                    <div>
                                                        Nội dung chuyển khoản:{" "}
                                                        <b>
                                                            {item.title} {profile?.username}
                                                        </b>
                                                    </div>
                                                </div>
                                            ) : null
                                        )}
                                </div>
                            )}
                            {activeOption == 2 && (
                                <div className="content_bank">
                                    {bank &&
                                        bank?.map((item) =>
                                            item.isShow && item.name_bank.toLowerCase() == "momo" ? (
                                                <div className="item-bank" style={{ width: `${item?.qr ? "90%" : null}` }}>
                                                    {item.qr && (
                                                        <div>
                                                            <img src={item.qr} style={{ marginRight: "15px", width: "150px", float: "left" }} />
                                                        </div>
                                                    )}
                                                    <div>
                                                        Số Momo: <b>{item.stk}</b>
                                                    </div>
                                                    <div>
                                                        Tên người nhận: <b>{item.fullname}</b>
                                                    </div>
                                                    <div>
                                                        Nội dung chuyển khoản:{" "}
                                                        <b>
                                                            {item.title} {profile?.username}
                                                        </b>
                                                    </div>
                                                </div>
                                            ) : null
                                        )}
                                </div>
                            )}
                            {bank && (
                                <>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div>
                                            <div className="input">
                                                <label>Nhập số tiền</label>
                                                <input
                                                    className="ipadd"
                                                    type="text"
                                                    value={newMoney}
                                                    {...register("money")}
                                                    onClick={() => setNewMoney(null)}
                                                    onChange={(e) => setNewMoney(Number(e.target.value.replaceAll(".", "").replaceAll(",", "")).toLocaleString('vi-VN'))}
                                                />
                                                <button type="submit" className="btn-submit" style={{ marginLeft: "10px" }}>
                                                    Xác nhận
                                                </button>
                                            </div>
                                            <div style={{ display: "none" }}>
                                                <input className="ipadd" type="text" {...register("detail")} placeholder="Nhập mã giao dịch ngân hàng" defaultValue="Nạp" />
                                            </div>
                                        </div>
                                    </form>

                                    <div className="huongdan">
                                        <div className="title">Hướng dẫn nạp tiền</div>
                                        <ul>
                                            <li>Chuyển khoản đến thông tin ngân hàng/Momo ở trên.</li>
                                            <li>Vui lòng ghi đúng nội dung chuyển khoản gồm có cả tên đăng nhập của bạn</li>
                                            <li>
                                                Sau khi chuyển khoản thành công quý khách điền số tiền đã chuyển khoản vào ô "Nhập số tiền" và bấm xác nhận, tiền sẽ được cộng trong
                                                vòng 5 phút.
                                            </li>
                                            <li>Nếu sau 5 phút tiền không tự động cộng vào tài khoản quý khách, vui lòng liên hệ CSKH để được hỗ trợ nhanh nhất.</li>
                                        </ul>
                                    </div>
                                </>
                            )}
                        </div>
                    </>
                )}
            </div>
        </>
    );
}
export default Recharge;
